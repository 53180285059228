
    .backgroundImage{
      background-repeat: no-repeat;
      top: 0%;
      min-height: 300px;
      position: relative;
      background-size: contain;
      background-position: center bottom
    }

    .displayTopFlex{
      flex-wrap: wrap-reverse;
    }

    .imageService{
      background-repeat: no-repeat;
      top: 0%;
      width: 100%;
      height: 100%;
      position: relative;
      background-size: contain;
      background-position: center
    }

    .imageSoccerUp{
      top: -30px;
    }

    .imageServiceWrapper{
     height: 400px;
     width: 100%;
     position: relative;
    }

    .imageSoccerUp{
      top: 0px;
    }

@media screen and (min-width: 768px) {
    .imageSoccerUp{
       top: -30px;
     }
    .backgroundImage{
      background-repeat: no-repeat;
      height: 120%;
      width: 100%;
      top: -10%;
      min-height: 200px;
      position: absolute;
      background-size: contain;
      background-position: center;
    }

    .imageService{
      background-repeat: no-repeat;
      top: 0%;
      width: 100%;
      height: 100%;
      position: relative;
      background-size: contain;
      background-position: center
    }

    .imageServiceWrapper{
     height: 400px;
     width: 100%;
     position: absolute;
    }
}

