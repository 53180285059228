.crossImage{
  min-width: 60px;
  width: 15%;
  max-width: 150px;
}

.squiggleImage{
  min-width: 150px;
  width: 25%;
  max-width: 400px;
}