.mobile-nav{
  text-align: center;
}

#mobile-menu li{
margin-top: 0.5rem;

}
#desktop-menu li{
  padding-top: 8px;
  margin-right: 1rem;

}

header{z-index: 9999;}

#desktop-menu {
  margin-top: 0.5rem;
}

.logoTypeWrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.textNone {
  left: -100%;
  transition: all 0.5s;
  transform: translateY(-100%);
}

.regular{
  left: 0;
  transition: all 0.5s;
  transform: translateY(-100%);

}